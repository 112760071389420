import {
    Box,
    Grid,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
    Container,
    Button,
  } from '@mui/material';
  import { makeStyles } from '@mui/styles';
  import React, { useEffect } from 'react';
  import welcomeCheckGreen from '../assets/images/WelcomeCheckGreen.svg';
  import WarningWelcome from '../assets/images/WarningWelcome.svg';
  import { useLocation } from 'react-router-dom';
  import {createEmission, createEmissionAssistants} from '../api/emission'
  import { getAccessToken, getAccessTokenAssistans } from '../api/token_mit';
  import LoadingGeneral from '../components/Loading/Loading_general';
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      background: 'white',
      paddingBottom: '60px',
    },
  }));
  export const PaymitAssistants: React.FC = () => {
    const classes = useStyles();
    const { breakpoints } = useTheme();
    const isDesktop = useMediaQuery(breakpoints.up('lg'));
    const [loading, setLoading] = React.useState(false);
    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    const type = searchParams.get('success');
    const Noperation = searchParams.get('operacion');
    const NoAuth = searchParams.get('nuAut');
    const date = searchParams.get('fecha');
    const bank = searchParams.get('banco');
    const nbResponse = searchParams.get('nbResponse');
    const company = searchParams.get('empresa');
    const branch = searchParams.get('sucursal');
    const email = searchParams.get('email');
    const nb_merchant = searchParams.get('nb_merchant');
    const amount = searchParams.get('importe');
    const reference = searchParams.get('referencia');
    const nbCoin = searchParams.get('nbMoneda');
    const cdCompany = searchParams.get('cdEmpresa');
    const idLiga = searchParams.get('idLiga');
    const image = nbResponse == 'Aprobado' ? welcomeCheckGreen : WarningWelcome;
    const text = nbResponse == 'Aprobado' ? 'Pago exitoso' : 'Pago rechazado';
    const text_auth=NoAuth ? "# de Autorizacion :":"";
    const text_operation=Noperation ? "# de Operacion :":"";
    const text_bank=bank ? "Banco :":"";
    const text_merchant=nb_merchant ? "# de Afiliación:Sucursal :":"";
    const txt = 'Ha ocurrido un error al generar la liga de pago';
    const [error, seterror] = React.useState(false);
    const handleClick = async () => {
      seterror(false)
      setLoading(true);
      localStorage.removeItem("token_mit");
      await getAccessTokenAssistans();
     let token_mit=localStorage.getItem("token_mit");
      let result=await createEmissionAssistants(token_mit,{"reference":reference});
      if(result?.data?.serice_url){
        window.location.href=result.data.serice_url
        setLoading(false)
      }else{
        setLoading(false)
        seterror(true)
      }
    }
    const loadingImage ='./infinite-spinner.svg';
    const button = 
    <Button
            variant="contained"
            onClick={handleClick}
            size={'medium'}
            style={{
              marginTop:"40px",
              fontWeight:700,
              textTransform: 'none',
              height: '55px',
              borderRadius: '10px',
              width: '100%',
              
            }}
          >
            Intentar pago de nuevo
          </Button>
    return (
      <div className={classes.root}>
        <LoadingGeneral open={loading} handleClose={() => true} scr={loadingImage} />
        <Container maxWidth="sm" style={{background:"white"}}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          ></Box>
          <Box
            component={'img'}
            src={image}
            sx={{
              width: 80,
            }}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            alt="Bienvenido"
            margin="20px auto"
          />
          <Box
            style={{
              padding:"20px"
            }}
          >
                <Typography
            style={{ fontWeight: 700, margin: 'auto', textAlign: 'center',paddingBottom:"30px" }}
            fontSize={`${isDesktop ? '28px' : '20px'}`}
          >
            {text}
          </Typography>
            <Grid container>
              <Grid item xs={6} sm={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 700,
                    maxWidth: '100%',
                    wordSpacing: '2px',
                    paddingLeft:"30px"
                  }}
                  fontSize="16px"
                >
              Estatus de pago :
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 400,
                    maxWidth: '600px',
                    wordSpacing: '2px',
                    textAlign: 'left',
                  }}
                  fontSize="16px"
                >
                  {nbResponse}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 700,
                    maxWidth: '100%',
                    wordSpacing: '2px',
                    paddingLeft:"30px"
                  }}
                  fontSize="16px"
                >
                 {text_auth}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 400,
                    maxWidth: '600px',
                    wordSpacing: '2px',
                    textAlign: 'left',
                  }}
                  fontSize="16px"
                >
                  {NoAuth}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 700,
                    maxWidth: '100%',
                    wordSpacing: '2px',
                    paddingLeft:"30px"
                  }}
                  fontSize="16px"
                >
                 {text_operation}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 400,
                    maxWidth: '600px',
                    wordSpacing: '2px',
                    textAlign: 'left',
                  }}
                  fontSize="16px"
                >
                  {Noperation}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 700,
                    maxWidth: '100%',
                    wordSpacing: '2px',
                    paddingLeft:"30px"
                  }}
                  fontSize="16px"
                >
                  Fecha :
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 400,
                    maxWidth: '600px',
                    wordSpacing: '2px',
                    textAlign: 'left',
                  }}
                  fontSize="16px"
                >
                  {date}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 700,
                    maxWidth: '100%',
                    wordSpacing: '2px',
                    paddingLeft:"30px"
                  }}
                  fontSize="16px"
                >
                  {text_bank}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 400,
                    maxWidth: '600px',
                    wordSpacing: '2px',
                    textAlign: 'left',
                  }}
                  fontSize="16px"
                >
                  {bank}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 700,
                    maxWidth: '100%',
                    wordSpacing: '2px',
                    paddingLeft:"30px"
                  }}
                  fontSize="16px"
                >
                 {text_merchant}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 400,
                    maxWidth: '600px',
                    wordSpacing: '2px',
                    textAlign: 'left',
                  }}
                  fontSize="16px"
                >
                  {nb_merchant}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 700,
                    maxWidth: '100%',
                    wordSpacing: '2px',
                    paddingLeft:"30px"
                  }}
                  fontSize="16px"
                >
                 Sucursal:
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 400,
                    maxWidth: '600px',
                    wordSpacing: '2px',
                    textAlign: 'left',
                  }}
                  fontSize="16px"
                >
                  {branch}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 700,
                    maxWidth: '100%',
                    wordSpacing: '2px',
                    paddingLeft:"30px"
                  }}
                  fontSize="16px"
                >
                 Nombre de la empresa:
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 400,
                    maxWidth: '600px',
                    wordSpacing: '2px',
                    textAlign: 'left',
                  }}
                  fontSize="16px"
                >
                  {company}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 700,
                    maxWidth: '100%',
                    wordSpacing: '2px',
                    paddingLeft:"30px"
                  }}
                  fontSize="16px"
                >
                 Referencia:
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 400,
                    maxWidth: '600px',
                    wordSpacing: '2px',
                    textAlign: 'left',
                  }}
                  fontSize="16px"
                >
                  {reference}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 700,
                    maxWidth: '100%',
                    wordSpacing: '2px',
                    paddingLeft:"30px"
                  }}
                  fontSize="16px"
                >
                 Moneda:
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 400,
                    maxWidth: '600px',
                    wordSpacing: '2px',
                    textAlign: 'left',
                  }}
                  fontSize="16px"
                >
                  {nbCoin}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 700,
                    maxWidth: '100%',
                    wordSpacing: '2px',
                    paddingLeft:"30px"
                  }}
                  fontSize="16px"
                >
                 Codigo de empresa:
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 400,
                    maxWidth: '600px',
                    wordSpacing: '2px',
                    textAlign: 'left',
                  }}
                  fontSize="16px"
                >
                  {cdCompany}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 700,
                    maxWidth: '100%',
                    wordSpacing: '2px',
                    paddingLeft:"30px"
                  }}
                  fontSize="16px"
                >
                 idLiga:
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 400,
                    maxWidth: '600px',
                    wordSpacing: '2px',
                    textAlign: 'left',
                  }}
                  fontSize="16px"
                >
                  {idLiga}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 700,
                    maxWidth: '100%',
                    wordSpacing: '2px',
                    paddingLeft:"30px"
                  }}
                  fontSize="16px"
                >
                 Email:
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 400,
                    maxWidth: '600px',
                    wordSpacing: '2px',
                    textAlign: 'left',
                  }}
                  fontSize="16px"
                >
                  {email}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 700,
                    maxWidth: '100%',
                    wordSpacing: '2px',
                    paddingLeft:"30px"
                  }}
                  fontSize="16px"
                >
                 Importe:
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontWeight: 400,
                    maxWidth: '600px',
                    wordSpacing: '2px',
                    textAlign: 'left',
                  }}
                  fontSize="16px"
                >
                  {amount}
                </Typography>
              </Grid>
            </Grid>
          {nbResponse!="Aprobado"?button:''}
          <Box
          style={{
            color:"red",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop:"10px",
            fontWeight:700,
          }}>
            {error==true?txt:''}
          </Box>
          </Box>
        </Container>
      </div>
    );
   
  };
  